<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Basic Information</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form v-if="show">
            <b-form-group id="input-group-0" label="Id:" label-for="input-0">
              <b-form-input
                id="input-0"
                v-model="disease.id"
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Name:"
              label-for="input-1"
              description="e.g. Atopic Dermatitis (Eczema)"
            >
              <b-form-input
                id="input-1"
                v-model="disease.name"
                :disabled="user.userTypeId != 4"
                required
                placeholder="Enter name"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Description:"
              label-for="input-2"
              description="e.g. A skin condition that causes patches of skin to be itchy."
            >
              <b-form-textarea
                id="input-2"
                v-model="disease.description"
                placeholder="Enter description"
                :disabled="user.userTypeId != 4"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Disease Status:"
              label-for="input-3"
            >
              <b-form-select
                id="input-3"
                v-model="disease.status"
                :options="status_options"
                :disabled="user.userTypeId != 4"
                required
              ></b-form-select>
            </b-form-group>

            <b-button
              @click="onSubmit"
              variant="primary"
              v-if="user.userTypeId == 4"
              >Update</b-button
            >
            <b-button
              v-b-modal.modal-delete-disease-confirmation
              variant="danger"
              v-if="user.userTypeId == 4"
              >Delete</b-button
            >
          </b-form>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Studies</h3>
          </div>
          <div class="card-toolbar">
            <div class="justify-content-center" v-if="user.userTypeId == 4">
              <b-button v-b-modal.modal-add-new-study variant="info"
                >New</b-button
              >
            </div>
          </div>
        </div>
        <div class="card-body">
          <b-table
            hover
            bordered
            v-if="disease.studies"
            :items="disease.studies"
            :fields="table_fields"
            @row-clicked="(item, index, event) => rowClickHandler(item)"
          ></b-table>
        </div>
      </div>
    </div>
    <!-- begin::Modal -->
    <b-modal id="modal-delete-disease-confirmation" title="Delete Disease">
      <h4>Are you sure you want to delete this disease?</h4>
      <p>This action cannot be undone</p>
      <template #modal-footer="{ }">
        <b-button size="sm" @click="onCancelDeleteDisease"> Cancel </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="onConfirmDeleteDisease">
          Confirm
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
    <!-- begin::Modal -->
    <b-modal id="modal-add-new-study" title="Add new study for this disease">
      <b-form @submit="onNewStudy">
        <b-form-group id="input-group-1" label="Name:" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="modal_new_study_data.name"
            required
            placeholder="Enter name"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Description:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="modal_new_study_data.description"
            required
            placeholder="Enter description"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Study Tag (which patient facing side to link with):"
          label-for="input-3"
        >
          <b-form-select
            id="input-3"
            v-model="modal_new_study_data.study_tag"
            :options="study_tags"
          ></b-form-select>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ }">
        <b-button size="sm" variant="danger" @click="onCancelNewStudy">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="primary" @click="onNewStudy">
          Submit
        </b-button>
      </template>
    </b-modal>
    <!-- end::Modal -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "disease",
  data() {
    return {
      error_msg: null,
      modal_new_study_data: {
        name: null,
        description: null,
        diseaseId: null,
        study_tag: null,
      },
      status_options: [
        { text: "Select One", value: null },
        { text: "Active", value: true },
        { text: "Deactivated", value: false },
      ],
      show: true,
      disease: {
        id: null,
        name: "",
        description: "",
        status: null,
      },
      table_fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "Name" },
        { key: "description", label: "Description" },
        { key: "status", label: "Status" },
      ],

      // TODO: These should be fetched from database not hardcoded
      // (should add table for these which is foreign key to study and to auth)
      study_tags: [
        { text: "Eczema Net", value: "EN" },
        { text: "WE-PREVENT", value: "WEPREV" },
        { text: "HygieAI", value: "TEST" },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Disease" }]);
    this.refresh();
  },
  methods: {
    refresh() {
      ApiService.get("/diseases/" + this.$route.params.id).then(({ data }) => {
        this.disease = data.data;
        this.modal_new_study_data.diseaseId = this.disease.id;
      });
    },
    onSubmit() {
      ApiService.put("/diseases/" + this.$route.params.id, this.disease).then(
        ({ data }) => {
          this.$store.commit("UPDATE_DISEASE", data.data);
          this.refresh();
        }
      );
      alert("Succesfully updated disease details");
    },
    onCancelDeleteDisease(evt) {
      evt.preventDefault();
      this.$bvModal.hide("modal-delete-disease-confirmation");
    },
    onConfirmDeleteDisease() {
      ApiService.delete("/diseases/" + this.$route.params.id).then(() => {
        this.$store.commit("DELETE_DISEASE", this.disease.id);
        this.$router.push("/");
      });
    },
    onNewStudy(evt) {
      evt.preventDefault();
      ApiService.post("/studies", this.modal_new_study_data)
        .then(({ data }) => {
          this.$bvModal.hide("modal-add-new-study");
          this.$store.commit("ADD_STUDY", data.data);
          this.refresh();
        })
        // TODO: give more suitable error messages (dont give too much detail of backend)
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.error_msg = error.response.data.message;
          } else {
            this.error_msg = "An error occurred. Please try again later.";
          }
          this.$refs["error-modal"].show(); // Show the error popup
        });
    },
    onCancelNewStudy(evt) {
      evt.preventDefault();
      this.modal_new_study_data.name = "";
      this.$bvModal.hide("modal-add-new-study");
    },
    rowClickHandler(item) {
      this.$router.push("/study/" + item.id);
    },
  },
};
</script>
